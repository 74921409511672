.App {
    display: grid;
    gap: 5vh;
    min-height: 100vh;
    padding: 5vh 5vw;
    position: relative;
    box-sizing: border-box;
}

.main {
    display: flex;
    flex-direction: column;
    place-self: center;
}

.footer {
    align-self: end;
    place-self: center;
}
