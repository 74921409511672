.RichText {
    * {
        line-height: 1.5em;
        margin: 0 0 1rem 0;
    }
    *:last-child {
        margin-bottom: 0;
    }
    a {
        color: inherit;
        opacity: 0.8;
        text-decoration-thickness: 0.5px;
        text-underline-offset: 0.25rem;
        transition: opacity ease-in-out 0.2s;
        &:hover {
            opacity: 1;
            transition: opacity ease-in-out 0.2s;
        }
    }
    h1 {
        font: {
            size: 3.1rem;
            weight: normal;
        }
    }
    h2 {
        font: {
            size: 2.5rem;
            weight: normal;
        }
    }
    h3 {
        font: {
            size: 2rem;
            weight: normal;
        }
    }
    h4 {
        font: {
            size: 1.6rem;
            weight: normal;
        }
    }
    h5 {
        font: {
            size: 1.3rem;
            weight: normal;
        }
    }
    h6 {
        font: {
            size: 1.1rem;
            weight: normal;
        }
    }
    ol,
    ul {
        padding: 0;
    }
    ul {
        list-style-position: inside;
        list-style-type: '>  ';
    }
}
